<template>
	<section id="about" class="py-10">
		<v-container fluid>
			<p class="text-h4 text-center font-weight-bold text-uppercase mb-2">Networking con empresas</p>
			<p class="text-h6 text-center mx-auto" style="max-width: 800px">
				Seas estudiante o alumni, en OverSOS hay un hueco para que puedas conectar con empresas de distintos sectores.
			</p>
			<v-card max-width="1200" class="mt-15 mx-auto d-flex flex-wrap justify-space-around" flat tile color="transparent">
				<v-img height="300" contain src="@/assets/images/landing/career.svg" />
				<div class="d-flex flex-column justify-space-around">
					<div class="flex-grow-0 d-flex">
						<div>
							<v-icon size="50" class=""> mdi-account-group </v-icon>
						</div>
						<div class="mt-3 ml-5">
							<p class="text-h5 font-weight-bold mb-2">Talent Pool</p>
							<p class="text-subtitle-1">Que sean los reclutadores quienes te busquen proactivamente.</p>
						</div>
					</div>
					<div class="flex-grow-0 d-flex">
						<div>
							<v-icon size="50" class=""> mdi-briefcase-search </v-icon>
						</div>
						<div class="mt-3 ml-5">
							<p class="text-h5 font-weight-bold mb-2">Prácticas, trabajo, eventos...</p>
							<p class="text-subtitle-1">Encontrarás todo tipo de ofertas y acontecimientos para conectar con empresas.</p>
						</div>
					</div>
					<div class="flex-grow-0 d-flex">
						<div>
							<v-icon size="50" class=""> mdi-forum </v-icon>
						</div>
						<div class="mt-3 ml-5">
							<p class="text-h5 font-weight-bold mb-2">Comunicación abierta con reclutadores</p>
							<p class="text-subtitle-1">Podrás mantenerte en contacto con empresas gracias a nuestra mensajería interna.</p>
						</div>
					</div>
				</div>
			</v-card>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingNetworking',
}
</script>
